import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import BCrumbs from '../components/BCrumbs'
import HeaderGeneric from '../components/HeaderGeneric'
import writingHeader from '../assets/images/writing-header.jpg'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { allMarkdownRemark } = data // data.markdownRemark holds your post data
  const { edges } = allMarkdownRemark
  const blogTitle = 'Digital Tumbleweed: Blog'

  return (
    <Layout>
      <Helmet title={blogTitle} />

      <div id="main">
        <img src={writingHeader} alt="" className="header" />

        <BCrumbs />

        <HeaderGeneric title="Blog">
          <span>
            (<a href="/blog/tags">Tags</a>)
          </span>
        </HeaderGeneric>

        {edges.map(edge => {
          const {
            path,
            title,
            snippet,
            tags,
            authors,
            date,
            updated,
          } = edge.node.frontmatter

          return (
            <section id="content" className="main">
              <h3>
                <a href={path}>{title}</a>
              </h3>
              <h5>
                Created: {date}, Last Updated: {updated}
              </h5>

              <div className="blog-post-content blog-post-snippet">
                <p>{snippet}</p>

                {tags.find(tag => tag === 'book') && (
                  <ul>
                    <li>Authors:</li>
                    {authors.map((author, idx) => (
                      <li key={`author-${title}-${author}`}>
                        {author}
                        {idx < authors.length - 1 ? ',' : ''}{' '}
                      </li>
                    ))}
                  </ul>
                )}

                {(tags.length && (
                  <ul>
                    <li>Tags:</li>
                    {tags.map((tag, idx) => (
                      <li key={`tag-${title}-${tag}`}>
                        {tag}
                        {idx < tags.length - 1 ? ',' : ''}{' '}
                      </li>
                    ))}
                  </ul>
                )) ||
                  null}
              </div>
            </section>
          )
        })}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            date
            path
            title
            snippet
            tags
            authors
            updated
          }
        }
      }
    }
  }
`
